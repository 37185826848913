import { ApplicationRef, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HttpClientXsrfModule } from '@angular/common/http';

import { RouterModule, Routes } from '@angular/router';
import {MatSnackBar, MatSnackBarModule} from '@angular/material/snack-bar';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import { AppComponent } from './app.component';
import { MeetModule } from './meet/meet.module';
import { DirectivesModule } from './components/directives.module';
import { JwtModule } from '@auth0/angular-jwt';

import { MatSidenavModule } from '@angular/material/sidenav';
import { CookiePopup } from './cookie/cookiePopup.component';

import { environment } from './environments/environment';
import { AppRoutingModule } from './app-routing.module';

import { AuthGuard } from './auth/auth-guard.service';
import { AuthService } from './auth/auth.service';
import { UserService } from './auth/user.service';

import { LandingComponent } from './components/landing/landing.component';

import { MarkdownModule } from 'ngx-markdown';


export function tokenGetter() {
    return localStorage.getItem('id_token');
}

@NgModule({
    imports: [
        BrowserModule,
        HttpClientModule,
        HttpClientXsrfModule.withOptions({
            headerName: 'x-csrf-token',
        }),
        JwtModule.forRoot({
            config: {
                tokenGetter,
            },
        }),
        AppRoutingModule,
        MeetModule,
        DirectivesModule,

        BrowserAnimationsModule,
        MatSnackBarModule,
        FormsModule,
        ReactiveFormsModule,
        MarkdownModule.forRoot()
        // LandingComponent
        
    ],
    declarations: [AppComponent, CookiePopup, LandingComponent],
    bootstrap: [AppComponent],
    entryComponents: [CookiePopup],
    providers: [
        AuthGuard,
        AuthService,
        UserService
    ]
})
export class AppModule {
    static parameters = [ApplicationRef];

    constructor(public appRef: ApplicationRef) {
        this.appRef = appRef;
    }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './auth/auth-guard.service';

const routes: Routes = [
    {
      path: "about",
      loadChildren: () => import('./about/about.module').then(m => m.AboutModule),
      pathMatch: 'full'
    },
    {
      path: "build",
      loadChildren: () => import('./bot/bot.module').then(m => m.BotModule),
    },
    {
      path: "team",
      loadChildren: () => import('./meet/meet.module').then(m => m.MeetModule)
    },
    {
      path: "admin",
      loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
    },
    {
      path: "contact",
      loadChildren: () => import('./contact/contact.module').then(m => m.ContactModule),
      pathMatch: 'full'
    },
    {
      path: "updates",
      loadChildren: () => import('./updates/updates.module').then(m => m.UpdatesModule),
    },
    {
      path: "research",
      loadChildren: () => import('./research/research.module').then(m => m.ResearchModule),
    },
    {
      path: "home",
      loadChildren: () => import('./main/main.module').then(m => m.MainModule),
    },
    {
      path: "login",
      loadChildren: () => import('./account/login/login.module').then(m => m.LoginModule),
      pathMatch: 'full'
    },
    {
      path: "profile",
      loadChildren: () => import('./account/profile/profile.module').then(m => m.ProfileModule),
      pathMatch: 'full'
    },
    {
      path: "signup",
      loadChildren: () => import('./account/signup/signup.module').then(m => m.SignupModule),
      pathMatch: 'full'
    },
    {
      path: "legal",
      loadChildren: () => import('./legal/legal.module').then(m => m.LegalModule),
    },
    {
      path: "dashboard",
      loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
    },
    {
      path: '**',
      redirectTo: '/home',
      pathMatch: 'full',
    }
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,

    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {

}

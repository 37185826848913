import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { RouterModule, Routes } from '@angular/router';


import { TooltipModule } from 'ngx-bootstrap/tooltip';

import { MeetComponent } from './meet.component';
import { PersonComponent } from './person.component';
import { MatDividerModule } from '@angular/material/divider';
import { MatCardModule } from '@angular/material/card';
import { CommonModule } from '@angular/common';
import { DirectivesModule } from '../components/directives.module';


export const ROUTES: Routes = [
    { path: '', component: MeetComponent },
    { path: ':person', component: PersonComponent }
];


@NgModule({
    imports: [
        CommonModule,
        FormsModule,

        MatDividerModule,
        MatCardModule,

        RouterModule.forChild(ROUTES),


        TooltipModule.forRoot(),

        DirectivesModule
    ],
    declarations: [
        MeetComponent,
        PersonComponent
    ],

    exports: [
        MeetComponent,
        PersonComponent
    ],
})
export class MeetModule { }

export * from '../../server/src/config/environment/shared';


export const RequirementTypes: string[] = [
    'Total',
    'Count'
];

export const Pronouns: { value: string, display: string }[] = [
    {
        value: "she",
        display: "She/Her"
    },
    {
        value: "he",
        display: "He/Him"
    },
    {
        value: "they",
        display: "They/Them"
    },
    {
        value: "none",
        display: "Prefer not to Say"
    },
    {
        value: "custom",
        display: "Not Listed"
    }
]

export const ProfilePictures = [
    "bab_pfp_000",	
    "bab_pfp_003",	
    "bab_pfp_006",	
    "bab_pfp_009",
    "bab_pfp_012",
    "bab_pfp_015",
    "bab_pfp_018",
    "bab_pfp_001",
    "bab_pfp_004",
    "bab_pfp_007",
    "bab_pfp_010",
    "bab_pfp_013",
    "bab_pfp_016",
    "bab_pfp_019",
    "bab_pfp_002",
    "bab_pfp_005",
    "bab_pfp_008",
    "bab_pfp_011",
    "bab_pfp_014",
    "bab_pfp_017",
    "bab_pfp_020",
]


export const UNITY_CODE_FILE: string = "3f6fb66b7173247c5ec99d27068cfd06.wasm.unityweb";
export const UNITY_DATA_FILE: string = "e63fba31539233e62101964dfd7ac08e.data.unityweb";
export const UNITY_FRAMEWORK_FILE: string = "fab6bf040b64de2891983ea9ce17f052.js.unityweb";
export const UNITY_LOADER_FILE: string = "unitybuild.loader.js";
<app-header *ngIf="!isDialog" title="Sign Up" subTitle="" size="normal"></app-header>

<section class="page-wrapper">
    <form [formGroup]="signUpForm">
        <section [hidden]="part2" id="signupPart1">
            <section>
                <label for="email" hidden>Email</label>
                <input type="text" name="email" id="email" placeholder="Email" formControlName="email">
                <span class="error" *ngIf="email?.invalid && (email?.dirty || email?.touched)">Please enter a valid email address.</span>
            </section>
    
            <section>
                <label for="password" hidden>Password</label>
                <input type="password" name="password" id="password" placeholder="Password" formControlName="password">
                <span class="error" *ngIf="password?.invalid && (password?.dirty || password?.touched)">Password must be
                    between 8
                    and 128 characters.</span>
            </section>
    
            <section>
                <label for="confirmPassword" hidden>Confirm Password</label>
                <input type="password" name="confirmPassword" id="confirm-password" placeholder="Confirm Password" required
                    formControlName="confirmPassword" />
                <span class="error" *ngIf="confirmPassword?.invalid && (confirmPassword?.dirty || confirmPassword?.touched)">Password
                    must be
                    between 8
                    and 128 characters.</span>
    
                <span class="error" *ngIf="signUpForm?.errors?.mismatch">Passwords must match.</span>
            </section>

            <button class="button-primary" id="next" (click)="part2 = true" type="submit">
                Next
            </button>
        </section>

        <section [hidden]="!part2" id="signupPart2">
            <section>
                <label for="name" hidden>Name</label>
                <input type="text" id="name" name="name" placeholder="Name" required formControlName="name">
                <span class="error" *ngIf="name?.invalid && (name?.dirty || name?.touched)">A name is
                    required.</span>
            </section>

            <section>
                <label for="pronouns" class="input-label">
                    <h3>Pronouns:</h3>
                </label>
                <div class="customInput">
                    <select formControlName="pronouns" name="pronouns" id="pronouns">
                        <option *ngFor="let pronoun of pronounList" [value]="pronoun.value">{{pronoun.display}}
                        </option>
                    </select>
                    <input *ngIf="pronouns.value === 'custom'" type="text"
                        formControlName="customPronouns" name="customPronouns" id="customPronouns"
                        placeholder="Enter your pronouns here" />
                </div>
            </section>

            <section>
                <label for="dob" class="input-label">
                    <h3>Birthday:</h3>
                </label>
                <input type="date" formControlName="dob" name="dob" id="dob"
                    [value]="dob" />
            </section>

            <button class="button-primary" id="signup" (click)="register()" type="submit" [disabled]="signUpForm?.invalid">
                Sign Up
            </button>
            <button class="button-primary" id="back" (click)="part2 = false" type="submit">
                Back
            </button>
        </section>




        <section id="oauth-sign-in">
            <h5>Or sign in with:</h5>

            <button (click)="loginOauth('google')"><img class="oauth-button" src="../../assets/icons/google-circle.svg" alt="Google Sign In"></button>
        </section>

        <section>
            <h5>Already have an account?</h5>

            <a [routerLink]="['/login']">Log In</a>
        </section>

    </form>
</section>
import { Component, OnInit } from "@angular/core";
import { CurrentTeam, Alumni } from "./people";

@Component({
    selector: "meet",
    templateUrl: "./meet.html",
    styleUrls: ["./meet.scss"]
})
export class MeetComponent implements OnInit {
    currentTeam = CurrentTeam;
    alumni = Alumni;

    constructor() {
    }

    ngOnInit() {}
}

import { Injectable, EventEmitter, Output } from '@angular/core';
import { UserService } from './user.service';
import { HttpClient } from '@angular/common/http';
import { safeCb } from './util';
import { userRoles } from '../app.constants';
import { User, UserStatus } from '../shared/types/user';
import { lastValueFrom } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    _currentUser: User = {
        name: '',
        email: '',
        password: '',
        status: UserStatus.Disabled
    };
    @Output() currentUserChanged = new EventEmitter(true);
    userRoles = userRoles || [];
    UserService;

    static parameters = [HttpClient, UserService];
    constructor(public http: HttpClient, public userService: UserService) {
        this.http = http;
        this.UserService = userService;
    }

    /**
     * Check if userRole is >= role
     * @param {String} userRole - role of current user
     * @param {String} role - role to check against
     */
    static hasRole(userRole, role) {
        return userRoles.indexOf(userRole) >= userRoles.indexOf(role);
    }

    async refreshCurrentUser() {
        try {
            const user: User = await lastValueFrom(this.UserService.get());
            this.currentUser = user;
        } catch (error) {
            await this.logout();
        }
    }

    get currentUser() {
        return this._currentUser;
    }

    set currentUser(user) {
        this._currentUser = user;
        this.currentUserChanged.emit(user);
    }

    /**
     * Authenticate user and save token
     *
     * @param  {Object}   user     - login info
     * @param  {Function} [callback] - function(error, user)
     * @return {Promise}
     */
    async login({email, password}, callback) {
        let response = null;
        try {
            response = await lastValueFrom(this.http.post('/auth/local', {
                email,
                password
            }, {
                observe: 'response' // Get the full HttpResponse
            }));
        } catch (error) {
            let errorMessage = error;
            if (error.message) {
                errorMessage = error.message;
            }
            await this.logout();
            throw new Error(errorMessage);
        }

        if (response && response.status >= 200 && response.status < 300) {
            localStorage.removeItem('currentBot');

            const user: User = await lastValueFrom(this.UserService.get());
            this.currentUser = user;
            localStorage.setItem('user', JSON.stringify(user));
            safeCb(callback)(null, user);
        } else {
            throw new Error('Login failed'); // Throwing error for non-success status codes
        }
    }

    async loginOauth(provider) {
        let response = null;
        try {
            response = await lastValueFrom(this.http.get(`/auth/${provider}`, {
                observe: 'response'
            }));
        } catch (error) {
            let errorMessage = error;
            if (error.message) {
                errorMessage = error.message;
            }
            await this.logout();
            throw new Error(errorMessage);
        }

        if (response && response.status >= 200 && response.status < 300) {
            localStorage.removeItem('currentBot');

            const user: User = await lastValueFrom(this.UserService.get());
            this.currentUser = user;
            localStorage.setItem('user', JSON.stringify(user));
            return user;
        } else {
            throw new Error('OAuth login failed'); // Throwing error for non-success status codes
        }
    }

    /**
     * Delete access token and user info
     * @return {Promise}
     */
    async logout() {
        localStorage.removeItem("currentBot");
        localStorage.removeItem('user');
        // localStorage.removeItem('id_token');
        this.currentUser = {
            name: '',
            email: '',
            password: '',
            status: UserStatus.Disabled
        };

        return await lastValueFrom(this.http.post('/auth/logout', {}));
    }

    /**
     * Create a new user
     *
     * @param  {Object}   user     - user info
     * @param  {Function} callback - optional, function(error, user)
     * @return {Promise}
     */
    async createUser(user, callback?) {
        try {
            const createdUser: User = await lastValueFrom(this.UserService.create(user));

            this.currentUser = createdUser;
            return safeCb(callback)(null, createdUser);
        } catch (error) {
            await this.logout();
            safeCb(callback)(error);
            return null;
        }
    }

    /**
     * Change password
     *
     * @param  {String}   oldPassword
     * @param  {String}   newPassword
     * @param  {Function} [callback] - function(error, user)
     * @return {Promise}
     */
    changePassword(oldPassword, newPassword, callback) {
        return this.UserService.changePassword({id: this.currentUser._id}, oldPassword, newPassword)
            .toPromise()
            .then(() => safeCb(callback)(null))
            .catch(err => safeCb(callback)(err));
    }

    /**
     * Gets all available info on a user
     *
     * @param  {Function} [callback] - function(user)
     * @return {Promise}
     */
    getCurrentUser(callback?) {
        safeCb(callback)(this.currentUser);
        return this.currentUser;
    }

    /**
     * Gets all available info on a user
     *
     * @return {Object}
     */
    getCurrentUserSync() {
        return this.currentUser;
    }

    /**
     * Checks if user is logged in
     * @param {function} [callback]
     * @returns {Promise}
     */
    isLoggedIn(callback?) {
        let is = !!this.currentUser._id;
        safeCb(callback)(is);
        return is;
    }

    isProfileComplete(callback?) {
        let is = this.currentUser && this.currentUser.status === UserStatus.Active;
        safeCb(callback)(is);
        return Promise.resolve(is);
    }

    /**
     * Check if a user is an admin
     *
     * @param  {Function|*} [callback] - optional, function(is)
     * @return {Promise}
     */
    isAdmin(callback?) {
        const user = this.getCurrentUser();

        var is = user.role === 'admin';
        safeCb(callback)(is);
        return is;
    }

    /**
     * Get auth token
     *
     * @return {String} - a token string used for authenticating
     */
    getToken() {
        // return localStorage.getItem('id_token');
    }
}

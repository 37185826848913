//"active", "suspended", "disabled", "pending_profile"
export enum UserStatus {
    Active = "active",
    Suspended = "suspended",
    Disabled = "disabled",
    PendingProfile = "pending_profile",
}

export interface User {
    _id?: string;
    name: string;
    email: string;
    password: string;
    pronouns?: string;
    customPronouns?: string;
    dob?: any;
    status: UserStatus;
    role?: string;
    pfp?: string;
}

export const CurrentTeam = [
    {
        tag: 'dpittman',
        name: 'Dr. Daniel Pittman',
        position: 'Faculty Lead',
        imageWebP: '../../assets/images/about/dpittman.webp',
        imagePng: '../../assets/images/about/dpittman.png',
        links: [
            {
                text: 'LinkedIn',
                url: 'https://www.linkedin.com/in/danpittman1/',
            },
            {
                text: 'Twitter (X)',
                url: 'https://twitter.com/DrDanPittman',
            },
            {
                text: 'MSU Denver',
                url: 'https://red.msudenver.edu/expert/daniel-pittman/',
            },
        ],
        contact: 'daniel.pittman@du.edu',
        blurb: "Dr. Daniel Pittman is an interdisciplinary researcher committed to bridging technology and humanity, with a focus on Human-Computer Interaction, Human-Robot Interaction, Machine Learning, and Augmented/Virtual Reality, aiming to create ethical, accessible, and beneficial technologies. He leads the NSF-funded Sustainability Hub project at MSU Denver, which aims to consolidate Colorado's sustainability data into a central platform, enhancing community collaboration and decision-making.",
        firstName: 'Dr. Pittman',
    },
    {
        tag: 'kharing',
        name: 'Dr. Kerstin Haring',
        position: 'Faculty Lead',
        imageWebP: '../../assets/images/about/kharing.webp',
        imagePng: '../../assets/images/about/kharing.png',
        links: [            {
            text: 'LinkedIn',
            url: 'https://www.linkedin.com/in/kerstin-haring/',
        },
    ],
        contact: 'kerstin.haring@du.edu',
        blurb: 'Dr. Kerstin S. Haring is an Assistant Professor of Computer Science at the University of Denver (DU). She directs the Humane Robot Technology Laboratory (HuRoT) that envisions interdisciplinary research in robotics with the goal of improving human lives through the promotion of better technology. Before her appointment at DU, she researched Human-Machine-Teaming at the U.S. Air Force Academy, completed her PhD in Human Robot Interaction at the University of Tokyo in Japan and studied Computer Science at the University of Freiburg in Germany.',
        firstName: 'Dr. Haring',
    },
    {
        tag: 'pkim',
        name: 'Dr. Pilyoung Kim',
        position: 'Faculty Lead',
        imageWebP: '../../assets/images/misc/logo_bg_black.webp',
        imagePng: '../../assets/images/misc/logo_bg_black.png',
        links: [],
        contact: '',
        blurb: '',
        firstName: 'Dr. Kim',
    },
    {
        tag: 'bdossett',
        name: 'Ben Dossett',
        position: 'Development Lead',
        imageWebP: '../../assets/images/about/bdossett.webp',
        imagePng: '../../assets/images/about/bdossett2.jpg',
        links: [
            {
                text: 'bendossett.com',
                url: 'https://www.bendossett.com',
            },
            {
                text: 'LinkedIn',
                url: 'https://www.linkedin.com/in/benjamindossett',
            },
        ],
        contact: 'benjamin.dossett@du.edu',
        blurb: 'Ben is the Web & Unity Development Lead for the project. He leads the team in the development of the platform.',
        firstName: 'Ben',
    },
    {
        tag: 'mtoczek',
        name: 'Maisey Toczek',
        position: 'Design Lead',
        imageWebP: '../../assets/images/about/mtoczek.webp',
        imagePng: '../../assets/images/about/mtoczek.png',
        links: [
            {
                text: 'LinkedIn',
                url:'www.linkedin.com/in/maiseytoczek',
            },
        ],
        contact: 'maisey.toczek@du.edu',
        blurb: 'Maisey is the current design lead for the Build-A-Bot Team. She is a graduate student at the University of Denver with interests in social robotics and user experience. ',
        firstName: 'Maisey',
    },
    {
        tag: 'wlaity',
        name: 'Weston Laity',
        position: 'Unity Developer',
        imageWebP: '../../assets/images/misc/logo_bg_black.webp',
        imagePng: '../../assets/images/misc/logo_bg_black.png',
        links: [],
        contact: '',
        blurb: '',
        firstName: 'Weston',
    },
    {
        tag: 'jchin',
        name: 'Jenna Chin',
        position: 'Neuroscience Lead',
        imageWebP: '../../assets/images/misc/logo_bg_black.webp',
        imagePng: '../../assets/images/misc/logo_bg_black.png',
        links: [],
        contact: '',
        blurb: '',
        firstName: 'Jenna',
    },
    {
        tag: 'msaketa',
        name: 'Melaku Saketa',
        position: 'Web Developer',
        imageWebP: '../../assets/images/about/msaketa.jpg',
        imagePng: '../../assets/images/about/msaketa.jpg',
        links: [
            {
                text: 'LinkedIn',
                url: 'https://www.linkedin.com/in/melaku-saketa-790826289/'
            },
        ],
        contact: 'melaku.saketa@du.edu',
        blurb: 'Melaku is a web developer working on the Build-a-Bot page and has experience on the machine learning team.',
        firstName: 'Melaku',
    },
    {
        tag: 'mwageman',
        name: 'Mason Wageman',
        position: 'Web Developer',
        imageWebP: '../../assets/images/about/mwageman.jpg',
        imagePng: '../../assets/images/about/mwageman.jpg',
        links: [
            {
                text: 'LinkedIn',
                url: 'https://www.linkedin.com/in/mason-wageman-474136197/'
            },
        ],
        contact: 'mason.wageman@du.edu',
        blurb: 'Mason studies English and Computer Science at the University of Denver and assists in developing the project website.',
        firstName: 'Mason',
    },
    {
        tag: 'crhodes',
        name: 'Cora Rhodes',
        position: 'Robotics',
        imageWebP: '../../assets/images/about/crhodes.png',
        imagePng: '../../assets/images/about/crhodes.png',
        links: [
            {
                text: 'LinkedIn',
                url: 'https://www.linkedin.com/in/cora-rhodes-136b21264/'
            },
        ],
        contact: 'cora.rhodes@du.edu',
        blurb: 'Cora Rhodes is an accomplished engineering student at the University of Denver, specializing in mechanical systems and mechatronics, with a strong foundation in math and physics. She excels in leadership roles, technical and creative projects, and has contributed to academic publications and innovative robotics research.',
        firstName: 'Cora',
    },
    {
        tag: 'ibailon',
        name: 'Itzel Bailon',
        position: 'Developer',
        imageWebP: '../../assets/images/about/ibailon.png',
        imagePng: '../../assets/images/about/ibailon.png',
        links: [
            {
                text: 'LinkedIn',
                url: 'https://www.linkedin.com/in/itzel-bailon/'
            },
        ],
        contact: 'ibailon.ptc@gmail.com',
        blurb: 'Computer Science graduate from MSU Denver | Computer Organization II && Computer Science Principles Learning Assistant',
        firstName: 'Itzel',
    },
    {
        tag: 'rmamo',
        name: 'Robel Mamo',
        position: 'Unity Developer',
        imageWebP: '../../assets/images/about/rmamo.webp',
        imagePng: '../../assets/images/about/rmamo.png',
        links: [
            {
                text: 'LinkedIn',
                url: 'http://www.linkedin.com/in/robel-anbessa-a467bb143',
            },
        ],
        contact: 'robelanbessa86@gmail.com',
        blurb: 'Robel is a Unity developer for the project, working on various features in the Unity application.',
        firstName: 'Robel',
    },
    {
        tag: 'mblanding',
        name: 'Mike Blanding',
        position: 'Unity Developer',
        imageWebP: '../../assets/images/about/mblanding.webp',
        imagePng: '../../assets/images/about/mblanding.png',
        links: [],
        contact: '',
        blurb: 'Michael Blanding is a computer science undergraduate student at the University of Denver. He is assisting with Unity development and database analysis on the project.',
        firstName: 'Mike',
    },
    {
        tag: 'jellis',
        name: 'Josh Ellis',
        position: 'Developer',
        imageWebP: '../../assets/images/about/jellis.webp',
        imagePng: '../../assets/images/about/jellis.png',
        links: [
            {
                text: 'LinkedIn',
                url: 'https://www.linkedin.com/in/josh-ellis-7741b323b',
            },
        ],
        contact: '',
        blurb: 'Josh is a Computer Science student at the University of Denver. He is a front-end and machine learning developer for the project.',
        firstName: 'Josh',
    },
];


export const Alumni = [
    {
        tag: 'mbogran',
        name: 'Marley Bogran',
        position: 'Sound Designer',
        imageWebP: '../../assets/images/about/mbogran.webp',
        imagePng: '../../assets/images/about/mbogran.png',
        links: [],
        contact: 'marley.bogran.8@gmail.com',
        blurb: 'Marley composes background music to enhance the user\'s experience. They also create the sound effects used in the user interface.',
        firstName: 'Marley',
    },
    {
        tag: 'asanchez',
        name: 'Ashley Sanchez',
        position: '3D Modeler',
        imageWebP: '../../assets/images/about/asanchez.webp',
        imagePng: '../../assets/images/about/asanchez.png',
        links: [
            {
                text: 'Research Site',
                url: 'https://ashsanresearch.wixsite.com/sri2021',
            },
        ],
        contact: 'ashley.sanchez@du.edu',
        blurb: 'Ashley creates various robot parts which can have multiple uses depending on the user.',
        firstName: 'Ashley',
    },
    {
        tag: 'spatil',
        name: 'Sneha Patil',
        position: 'UI/UX Designer',
        imageWebP: '../../assets/images/about/spatil.webp',
        imagePng: '../../assets/images/about/spatil.png',
        links: [''],
        contact: '',
        blurb: '',
        firstName: 'Sneha',
    },
    {
        tag: 'hrodriguez',
        name: 'Hector A. Rodriguez',
        position: 'Developer',
        imageWebP: '../../assets/images/about/hrodriguez.webp',
        imagePng: '../../assets/images/about/hrodriguez.png',
        links: [],
        contact: '',
        blurb: '',
        firstName: 'Hector',
    },
    {
        tag: 'mbohn',
        name: 'Madeline Bohn',
        position: 'Developer',
        imageWebP: '../../assets/images/about/mbohn.webp',
        imagePng: '../../assets/images/about/mbohn.png',
        links: [],
        contact: '',
        blurb: '',
        firstName: 'Madeline',
    },
    {
        tag: 'jfamula',
        name: 'Jurgen Famula',
        position: 'Quality Assurance Tester',
        imageWebP: '../../assets/images/about/jfamula.webp',
        imagePng: '../../assets/images/about/jfamula.png',
        links: [],
        contact: '',
        blurb: 'Jurgen is a computer science graduate student at the University of Denver. He is the Quality Assurance Tester for the project.',
        firstName: 'Jurgen',
    },
    {
        tag: 'yluevano',
        name: 'Yahir Luevano',
        position: '3D Modeler',
        imageWebP: '../../assets/images/misc/logo_bg_black.webp',
        imagePng: '../../assets/images/misc/logo_bg_black.png',
        links: [],
        contact: '',
        blurb: '',
        firstName: 'Yahir',
    },
    {
        tag: 'yraz',
        name: 'Yasmin Raz',
        position: 'Unity Developer / 3D Modeler',
        imageWebP: '../../assets/images/about/yraz.webp',
        imagePng: '../../assets/images/about/yraz.png',
        links: [''],
        contact: '',
        blurb: '',
        firstName: 'Yasmin',
    },

    {
        tag: 'bkelsey',
        name: 'Braden Kelsey',
        position: 'Unity / Machine Learning Developer',
        imageWebP: '../../assets/images/about/bkelsey.webp',
        imagePng: '../../assets/images/about/bkelsey.png',
        links: [''],
        contact: '',
        blurb: '',
        firstName: 'Braden',
    },
    {
        tag: 'gstrasheim',
        name: 'Grace Strasheim',
        position: 'Machine Learning Lead',
        imageWebP: '../../assets/images/about/gstrasheim.webp',
        imagePng: '../../assets/images/about/gstrasheim.png',
        links: [
            {
                text: 'LinkedIn',
                url: 'http://www.linkedin.com/in/gracecs',
            },
        ],
        contact: 'grace.strasheim@du.edu',
        blurb: 'Grace is working as part of the machine learning team as well as working on web portions of the project.',
        firstName: 'Grace',
    },
    {
        tag: 'wbohrman',
        name: 'William Bohrmann',
        position: 'Database & Modeling Lead',
        imageWebP: '../../assets/images/about/wbohrman.webp',
        imagePng: '../../assets/images/about/wbohrman.png',
        links: [
            {
                text: 'LinkedIn',
                url: 'https://www.linkedin.com/in/william-bohrmann-a260ba219/',
            },
        ],
        contact: 'william.bohrmann@du.edu',
        blurb: 'William is the former Database & Modeling Lead, and worked with MongoDB as it relates to Unity, the website, and machine learning.',
        firstName: 'William',
    },
    {
        tag: 'ijohnson',
        name: 'Izzy Johnson',
        position:
            'Neuroscience / Machine Learning',
        imageWebP: '../../assets/images/about/ijohnson.webp',
        imagePng: '../../assets/images/about/ijohnson.png',
        links: [
            {
                text: 'LinkedIn',
                url: 'http://linkedin.com/in/isabelle-p-johnson-8a6440173/',
            },
            {
                text: 'Personal Site',
                url: 'https://isabelle-johnson-portfolio.glitch.me/',
            },
        ],
        contact: 'izzypj2000@gmail.com',
        blurb: 'Izzy works as part of the neuroscience experiments team, creating stimulus images for studies, as well as the 3D modeling, UX/UI design, and machine learning teams.',
        firstName: 'Izzy',
    },
    {
        tag: 'iqureshi',
        name: 'Ibraheem Qureshi',
        position: 'Unity Developer',
        imageWebP: '../../assets/images/misc/logo_bg_black.webp',
        imagePng: '../../assets/images/misc/logo_bg_black.png',
        links: [''],
        contact: '',
        blurb: '',
        firstName: 'Ibraheem',
    },
    {
        tag: 'egutierrez',
        name: 'Elizabeth Gutierrez-Gutierrez',
        position: 'Unity Developer',
        imageWebP: '../../assets/images/about/egutierrez.webp',
        imagePng: '../../assets/images/about/egutierrez.png',
        links: [
            {
                text: 'Research Site',
                url: 'https://egutierrez0555.wixsite.com/egutierrdubuildabot',
            },
            {
                text: 'LinkedIn',
                url: 'https://www.linkedin.com/in/elizabeth-gutier',
            },
        ],
        contact: 'elizabeth.gutierrez-gutier@du.edu',
        blurb: '',
        firstName: 'Elizabeth',
    },
    {
        tag: 'bhernandez',
        name: 'Beatriz Hernandez',
        position: 'Unity Developer / 3D Modeler',
        imageWebP: '../../assets/images/about/bhernandez.webp',
        imagePng: '../../assets/images/about/bhernandez.png',
        links: [''],
        contact: '',
        blurb: '',
        firstName: 'Beatriz',
    },
    {
        tag: 'hjaffray',
        name: 'Henry Jaffray',
        position: 'Unity Developer',
        imageWebP: '../../assets/images/about/hjaffray.webp',
        imagePng: '../../assets/images/about/hjaffray.png',
        links: [
            {
                text: 'henryjaffray.com',
                url: 'https://www.henryjaffray.com',
            },
        ],
        contact: 'HenryDJaffray@gmail.com',
        blurb: 'Henry works on object rotation/movement and other features in the Unity application.',
        firstName: 'Henry',
    },
    {
        tag: 'sgonzales',
        name: 'Sergio Gonzales',
        position: 'UI/UX Designer',
        imageWebP: '../../assets/images/misc/logo_bg_black.webp',
        imagePng: '../../assets/images/misc/logo_bg_black.png',
        links: [''],
        contact: '',
        blurb: '',
        firstName: 'Sergio',
    },
    {
        tag: 'uheredia',
        name: 'Ulises Heredia-Trinidad',
        position: '3D Artist',
        imageWebP: '../../assets/images/misc/logo_bg_black.webp',
        imagePng: '../../assets/images/misc/logo_bg_black.png',
        links: [''],
        contact: '',
        blurb: '',
        firstName: 'Ulises',
    },
    {
        tag: 'aayad',
        name: 'Abdulrahman Ayad',
        position: 'Unity Developer',
        imageWebP: '../../assets/images/misc/logo_bg_black.webp',
        imagePng: '../../assets/images/misc/logo_bg_black.png',
        links: [''],
        contact: '',
        blurb: '',
        firstName: 'Abdulrahman',
    },
    {
        tag: 'rvrooman',
        name: 'Ralph Vrooman',
        position: 'Unity Developer',
        imageWebP: '../../assets/images/about/rvrooman.webp',
        imagePng: '../../assets/images/about/rvrooman.png',
        links: [],
        contact: 'RalphCVrooman@gmail.com',
        blurb: '',
        firstName: 'Ralph',
    },
    {
        tag: 'rthapa',
        name: 'Raghav Thapa',
        position: 'Unity Developer',
        imageWebP: '../../assets/images/misc/logo_bg_black.webp',
        imagePng: '../../assets/images/misc/logo_bg_black.png',
        links: [],
        contact: '',
        blurb: '',
        firstName: 'Raghav',
    },
    {
        tag: 'gehman',
        name: 'Gillian Ehman',
        position: 'Lead Artist / 3D Modeler',
        imageWebP: '../../assets/images/about/gehman.webp',
        imagePng: '../../assets/images/about/gehman.png',
        links: [],
        contact: 'gilliantehman@gmail.com',
        blurb: '',
        firstName: 'Gillian',
    },
    {
        tag: 'nninos',
        name: 'Nicholas Ninos',
        position: 'Cloud Integration Specialist',
        imageWebP: '../../assets/images/misc/logo_bg_black.webp',
        imagePng: '../../assets/images/misc/logo_bg_black.png',
        links: [],
        contact: '',
        blurb: '',
        firstName: 'Nicholas',
    },
    {
        tag: 'eirby',
        name: 'Esabella Irby',
        position: 'UX Designer',
        imageWebP: '../../assets/images/about/eirby.webp',
        imagePng: '../../assets/images/about/eirby.png',
        links: [],
        contact: '',
        blurb: '',
        firstName: 'Esabella',
    },
    {
        tag: 'mpetersen',
        name: 'Max Petersen',
        position: 'Developer',
        imageWebP: '../../assets/images/misc/logo_bg_black.webp',
        imagePng: '../../assets/images/misc/logo_bg_black.png',
        links: [],
        contact: '',
        blurb: '',
        firstName: 'Max',
    },
];

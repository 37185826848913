import {Component, Inject, HostBinding} from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from "@angular/material/snack-bar";
import { Observable } from "rxjs";
/**
 * @title Basic snack-bar
 */
@Component({
    selector: 'cookie-popup',
    templateUrl: './cookiePopup.component.html',
    styleUrls: ['./cookiePopup.component.scss']
})

export class CookiePopup {

    @HostBinding('class') themeClassBinding: string;

    themeClass$: Observable<boolean>;

    constructor(
        public snackBarRef: MatSnackBarRef<CookiePopup>,
        @Inject(MAT_SNACK_BAR_DATA) data: any
    ) {
        this.themeClass$ = data.themeClass$;

        this.themeClass$.subscribe(isLightMode => {
            this.themeClassBinding = isLightMode ? "light-theme" : "dark-theme"; 
        });
    }

    acceptCookies() {
        let key = 'accepted_cookies';
        localStorage.setItem(key, 'true');
        this.snackBarRef.dismiss();
    }

    declineCookies() {
        let key = 'accepted_cookies';
        localStorage.setItem(key, 'false');
        this.snackBarRef.dismiss();
    }
}

import { Component, ElementRef, Input, OnInit } from "@angular/core";

import { Router, ActivatedRoute, NavigationEnd, Route, NavigationStart } from "@angular/router";
import { AuthService } from "../../auth/auth.service";
import { ThemeService } from '../../theme.service';
import { Subscription } from 'rxjs';

import { HostListener } from '@angular/core';
import { LoginDialogComponent } from "../../account/login/login.module";
import { SignupDialogComponent } from "../../account/signup/signup.module";
import { MatDialog } from "@angular/material/dialog";

@Component({
    selector: "navbar",
    templateUrl: "./navbar.html",
    styleUrls: ["./navbar.scss"],
})
export class NavbarComponent {
    @Input() headerSize;

    isCollapsed = true;

    buttonsLoggedIn = [
        {
            title: "Build",
            link: "/build"
        }
    ];

    buttonsLoggedInGameMode = [
        {
            title: "Dashboard",
            link: "/dashboard"
        }
    ]

    Router: Router;
    isAdmin;
    isLoggedIn;
    isProfileComplete;
    isInGame;
    currentUser = {
        name: undefined,
    };
    AuthService;

    siteWrapper;

    isLightTheme: boolean = false;
    themeSubscription: Subscription;

    navClass = "";

    showMenuDropdown: boolean = false;
    pageIsBuilder: boolean = false;

    static parameters = [AuthService, Router, ElementRef, ThemeService, ActivatedRoute, MatDialog];
    constructor(
        public authService: AuthService,
        public router: Router,
        public elementRef: ElementRef,
        private themeService: ThemeService,
        private activatedRoute: ActivatedRoute,
        public dialog: MatDialog
    ) {
        this.AuthService = authService;

        this.Router = router;
        this.activatedRoute = activatedRoute;

        this.Router.events.subscribe(event => {
            if (event instanceof NavigationStart)
                this.showMenuDropdown = false;
        })

        this.reset();

        this.AuthService.currentUserChanged.subscribe((user) => {
            this.currentUser = user;
            this.reset();
        });

        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.pageIsBuilder = this.router.url === '/build';
                this.onWindowScroll();
            }
        });
    }

    ngOnInit() {

        this.themeSubscription = this.themeService.isLightTheme$.subscribe(isLightTheme => {
            this.isLightTheme = isLightTheme;
        });


    }

    // @HostListener('window:scroll', [])
    // onWindowScroll() {
    //     if (window.scrollY > 75) {
    //         this.navClass = 'nav-bg-color' + this.pageIsBuilder ? ' custom-nav' : '';
    //     } else {
    //         this.navClass = this.pageIsBuilder ? 'custom-nav' : '';
    //     }
    // }

    @HostListener("window:scroll", [])
    onWindowScroll() {
        if (window.scrollY > 75) {
            this.navClass = "nav-bg-color";
        }
        else {
            if(this.isLightTheme) {
                this.navClass = this.pageIsBuilder ? 'custom-nav' : '';
            } else {
                this.navClass = '';
            }
        }
    }

    @HostListener("document:click", ["$event"])
    onDocumentClick(event) {
        if (this.showMenuDropdown && 
            !event.target?.classList.contains("dropdown-items") && 
            event.target.id != "user-dropdown" && 
            !event.target?.classList.contains("dropdown-toggle")) {
            this.showMenuDropdown = false;
        }
    }

    toggleDropdown(event: Event) {
        event.preventDefault();
    }

    toggleMenuDropdown() {
        this.showMenuDropdown = !this.showMenuDropdown;
    }

    ngOnDestroy() {
        if (this.themeSubscription) {
            this.themeSubscription.unsubscribe();
        }
    }

    toggleLightTheme() {
        this.themeService.toggleTheme();
        this.onWindowScroll();
    }

    reset() {
        this.isProfileComplete = false;
        this.isAdmin = false;
        this.currentUser = null;
        this.showMenuDropdown = false;

        this.isLoggedIn = this.AuthService.isLoggedIn();

        if (this.isLoggedIn) {
            this.AuthService.isProfileComplete().then((is) => {
                this.isProfileComplete = is;
            });

            this.isAdmin = this.AuthService.isAdmin();
            this.currentUser = this.AuthService.getCurrentUser();
        } else {
            this.isProfileComplete = false;
            this.isAdmin = false;
            this.currentUser = null;
        }
    }

    login() {
        const dialogRef = this.dialog.open(LoginDialogComponent, {
            backdropClass: "dialog-backdrop",
            closeOnNavigation: true
        });
    }

    signup() {
        const dialogRef = this.dialog.open(SignupDialogComponent, {
            backdropClass: "dialog-backdrop",
            closeOnNavigation: true
        });
    }

    logout() {
        return this.AuthService.logout().then(() => {
            this.reset();
            this.Router.navigateByUrl("/home");
        });
    }
}

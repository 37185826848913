// @flow
import { Component, EventEmitter, Output, Input } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import {
    MatDialogRef,
} from '@angular/material/dialog';
import { AuthService } from '../../auth/auth.service';

interface User {
    name: string;
    email: string;
    password: string;
}

@Component({
    selector: 'login',
    templateUrl: './login.html',
    styleUrls: ['../account.scss']
})
export class LoginComponent {
    user: User = {
        name: '',
        email: '',
        password: '',
    };

    submitted = false;
    AuthService;
    Router;

    @Input() isDialog: boolean = false;

    error: boolean = false;
    errors: any = {};

    @Output() onLoggedIn = new EventEmitter<boolean>();

    loginForm = new FormGroup({
        email: new FormControl('', [Validators.required, Validators.email]),
        password: new FormControl('', [Validators.required]),
    });

    get email() { return this.loginForm.get('email'); }
    get password() { return this.loginForm.get('password'); }


    static parameters = [AuthService, Router, MatDialogRef];
    constructor(_AuthService_: AuthService, router: Router) {
        this.AuthService = _AuthService_;
        this.Router = router;
    }

    login() {
        if (this.loginForm?.invalid) return;
        return this.AuthService.login({
            email: this.email.value,
            password: this.password.value
        })
            .then(() => {
                if (this.AuthService.isProfileComplete) {
                    // Logged in, profile complete, redirect to home
                    this.Router.navigateByUrl('/home');
                } else {
                    // Send to profile page
                    this.Router.navigateByUrl('/profile');
                }
                this.onLoggedIn.emit(true);
            })
            .catch(err => {
                this.error = true;
                this.errors = err;
                this.onLoggedIn.emit(false);
            });
    }

    loginOauth(provider) {
        window.location.href = `/auth/${provider}`;
    }
}

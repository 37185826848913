<div class="cookie-popup">
    <h3>
        Cookies & Privacy
    </h3>
    <p>
        This website uses cookies to ensure you get the best experience on our website.
    </p>
    <div class="links">
        <a href="/tos">Terms and Conditions</a>
        <br/>
        <a href="/cookies">Cookie Policy</a>
    </div>
    <div class="button-container">
        <button class="decline-button" (click)="declineCookies()">Decline</button>
        <button class="accept-button" (click)="acceptCookies()">Accept</button>
    </div>
</div>

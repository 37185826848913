// @flow
import { Component, EventEmitter, Output, Input } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl } from '@angular/forms';
import { Validators, ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';

import { AuthService } from '../../auth/auth.service';

import { Pronouns } from '../../app.constants';

import { User, UserStatus } from "../../shared/types/user";

@Component({
    selector: 'signup',
    templateUrl: './signup.html',
    styleUrls: ['../account.scss']
})
export class SignupComponent {

    @Input() isDialog: boolean = false;

    part2: boolean = false;

    signUpForm = new FormGroup({
        name: new FormControl('', [Validators.required]),
        email: new FormControl('', [Validators.required, Validators.email]),
        password: new FormControl('', [Validators.required, Validators.minLength(8), Validators.maxLength(128)]),
        confirmPassword: new FormControl('', [Validators.required, Validators.minLength(8), Validators.maxLength(128)]),
        pronouns: new FormControl('', [Validators.required]),
        dob: new FormControl("", [Validators.required]),
        customPronouns: new FormControl("", []),
    }, { validators: this.passwordMatchValidator });

    get name() { return this.signUpForm.get('name'); }
    get email() { return this.signUpForm.get('email'); }
    get password() { return this.signUpForm.get('password'); }
    get confirmPassword() { return this.signUpForm.get('confirmPassword'); }
    get pronouns() { return this.signUpForm.get('pronouns'); }
    get dob() { return this.signUpForm.get('dob'); }
    get customPronouns() { return this.signUpForm.get('customPronouns'); }

    // https://angular.io/api/forms/FormGroup#create-a-form-group-with-a-group-level-validator
    passwordMatchValidator(g: FormGroup) {
        return g.get('password').value === g.get('confirmPassword').value
            ? null : { 'mismatch': true };
    }

    confirmPasswordError: string = null;

    getConfirmPasswordErrorMessage() {
        this.confirmPasswordError = this.confirmPassword.hasError('required') ? 'You must enter a password' :
            this.confirmPassword.hasError('minlength') ? 'Password must be at least 8 characters long' :
                this.confirmPassword.hasError('maxlength') ? 'Password cannot be more than 128 characters long' :
                    this.signUpForm.hasError('mismatch') ? 'Passwords do not match' :
                        '';
    }

    errors: any = {};

    submitted = false;
    AuthService: AuthService;
    Router;
    pC;

    pronounList;

    @Output() onSignup = new EventEmitter<boolean>();


    static parameters = [AuthService, Router];

    constructor(_AuthService_: AuthService, router: Router) {
        this.AuthService = _AuthService_;
        this.Router = router;

        this.pronounList = Pronouns;
    }

    register() {
        if (this.signUpForm?.invalid) return;

        this.submitted = true;

        return this.AuthService.createUser({
            name: this.name.value,
            email: this.email.value,
            password: this.password.value,
            status: UserStatus.Active,
            pronouns: this.pronouns.value === "custom" ? this.customPronouns : this.pronouns.value,
            dob: this.dob.value
        })
            .then(() => {
                // Account created, redirect to profile
                this.Router.navigateByUrl('/profile');

                this.onSignup.emit(true);
            })
            .catch((err: { error: { errors: { field: any } } }) => {
                console.log(err);
                this.errors = err.error.errors;

                // Update validity of form fields that match the mongoose errors
                Object.entries(err.error.errors).forEach(([field, error]: [string, any]) => {
                    this.errors[field] = error.message;

                    if (field === 'email' && error.kind === 'user defined') {
                        this.signUpForm.controls[field].setErrors({ inUse: true });
                    }
                });

                this.submitted = false;
                this.onSignup.emit(false);
            });
    }

    loginOauth(provider) {
        window.location.href = `/auth/${provider}`;
    }
}

<section class="banner">
    <article>
        <h1>{{person.name}}</h1>
        <h3>{{person.position}}</h3>
    </article>
</section>


<section class="person-body">
    <article>
        <img [src]="person.imagePng" alt="Profile Picture">
    </article>
    <article class="side-text">
        <section>
            <h2>About {{person.firstName}}</h2>
            <p>{{person.blurb}}</p>
        </section>

        <section>
            <h2>Contact:</h2>
            <h3><a href="mailto:{{person.contact}}">{{person.contact}}</a></h3>
            <h3 *ngFor="let link of person.links">
                <a href="{{link.url}}" target="_blank">{{link.text}}</a>
            </h3>
        </section>

    </article>
</section>

import {Component, OnInit, Inject} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {CookiePopup} from './cookie/cookiePopup.component';
import {BehaviorSubject} from 'rxjs';
import {OverlayContainer} from '@angular/cdk/overlay';
import { ThemeService } from './theme.service';
import { Router, ActivatedRoute, NavigationStart } from '@angular/router';
import {AuthService} from './auth/auth.service';

require('./assets/scripts/modernizr-custom');

@Component({
    selector: 'app',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})


export class AppComponent implements OnInit {
    themeClass$ = new BehaviorSubject<string>('light-theme');

    showLanding: boolean = false;

    static parameters = [AuthService, MatSnackBar, OverlayContainer, ThemeService, Router];
    constructor(
        private authService: AuthService,
        @Inject(MatSnackBar) private _snackBar: MatSnackBar,
        private overlayContainer: OverlayContainer,
        @Inject(ThemeService) private themeService: ThemeService,
        private router: Router
    ) {
        this.router = router;
        if (this.authService.isLoggedIn()) {
            this.authService.refreshCurrentUser();
        }
    }

    // On initialization, get the "accepted_cookies" from localStorage. If cookies haven't been
    // accepted yet, display the cookie popup. Else, don't
    ngOnInit() {
        let visited = localStorage.getItem("visited");
        if (visited) {
            this.showLanding = false;
        } else {
            this.showLanding = true;
            localStorage.setItem("visited", "true");
        }

        this.router.events.subscribe(event => {
            if (event instanceof NavigationStart)
                if (event.url != "/home") {
                    this.showLanding = false;
                }
        })

        let acceptedCookiesValue = localStorage.getItem("accepted_cookies");

        this.themeService.isLightTheme$.subscribe((isLightTheme) => {
            const overlayClassList = this.overlayContainer.getContainerElement().classList;
            const lightClassName = 'light-theme';
            const darkClassName = 'dark-theme';

            if (isLightTheme) {
                overlayClassList.add(lightClassName);
                overlayClassList.remove(darkClassName);
            } else {
                overlayClassList.add(darkClassName);
                overlayClassList.remove(lightClassName);
            }
        });

        if (acceptedCookiesValue == null) {
            let snackBarRef = this._snackBar.openFromComponent(CookiePopup, {
                data: { themeClass$: this.themeService.isLightTheme$ }
            });
        }
    }
}

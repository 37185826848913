import { Component, Input, OnInit, ElementRef } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { ThemeService } from '../../theme.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  @Input() title: string;
  @Input() subTitle: string;
  @Input() size: string;

  showLanding: boolean;

  isLightTheme: boolean = false;
  themeSubscription: Subscription;
  siteWrapper;

  static parameters = [Router, ThemeService, ElementRef];
  constructor(
    private router: Router,
    private themeService: ThemeService,
    public elementRef: ElementRef,
  ) {
    this.router = router;
  }

  ngOnInit() {
    let visited = localStorage.getItem("visited");
    if (visited) {
      this.showLanding = false;
    } else {
      this.showLanding = true;
      localStorage.setItem("visited", "true");
    }

    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart)
        if (event.url != "/home") {
          this.showLanding = false;
        }
    });

    this.siteWrapper = this.elementRef.nativeElement.parentElement.parentElement;

    this.themeSubscription = this.themeService.isLightTheme$.subscribe(isLightTheme => {
      this.isLightTheme = isLightTheme;

      if (this.isLightTheme) {
        this.siteWrapper.classList.add('light-theme');
        this.siteWrapper.classList.remove('dark-theme');
      } else {
        this.siteWrapper.classList.add('dark-theme');
        this.siteWrapper.classList.remove('light-theme');
      }
    });
  }

  ngOnDestroy() {
    if (this.themeSubscription) {
      this.themeSubscription.unsubscribe();
    }
  }
}

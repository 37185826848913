<!-- <section class="banner">
    <article>
        <h1>Meet the Team</h1>
    </article>
</section> -->

<app-header title="Meet the Team" subTitle="" size="normal"></app-header>

<main>
    <section class="section-header">
        <h1>Team</h1>
        <hr>
    </section>
    <section class="card-set" id="current-team">
        <article *ngFor="let p of currentTeam" class="card-round" [routerLink]="['/team/' + p['tag']]">
            <img [src]="p.imagePng" alt="Profile Picture" class="profile-card-image">
            <div class="profile-card-text">
                <h4 class="profile-card-name">{{p.name}}</h4>
                <p class="profile-card-position">{{p.position}}</p>
            </div>
        </article>
    </section>

    <section class="section-header">
        <h1>Alumni</h1>
        <hr>
    </section>
    <section class="card-set" id="alumni">
        <article *ngFor="let p of alumni" class="card-round" [routerLink]="['/team/' + p['tag']]">
            <img [src]="p.imagePng" alt="Profile Picture" class="profile-card-image">

            <div class="profile-card-text">
                <h4 class="profile-card-name">{{p.name}}</h4>
                <p class="profile-card-position">{{p.position}}</p>
            </div>
        </article>
    </section>
</main>
<app-header *ngIf="!isDialog" title="Log In" subTitle="" size="normal"></app-header>

<section class="page-wrapper">
    <form [formGroup]="loginForm">

        <section>
            <label for="email" hidden>Email</label>
            <input type="text" name="email" id="email" placeholder="Email" formControlName="email">
            <span class="error" *ngIf="email?.invalid && (email?.dirty || email?.touched)">Please enter a valid email address.</span>
            <span class="error" *ngIf="(email?.errors ? email?.errors.inUse : false)">This email address is already in use.</span>
        </section>

        <section>
            <label for="password" hidden>Password</label>
            <input type="password" name="password" id="password" placeholder="Password" formControlName="password">
            <span class="error" *ngIf="password?.invalid && (password?.dirty || password?.touched)">Password must be
                between 8
                and 128 characters.</span>
        </section>

        <span class="error" *ngIf="error">Invalid credentials. Please try again.</span>

        <button class="button-primary" id="login" (click)="login()" type="submit" [disabled]="loginForm?.invalid">
            Log In
        </button>

        <section id="oauth-sign-in">
            <h5>Or sign in with:</h5>

            <button (click)="loginOauth('google')"><img class="oauth-button" src="../../assets/icons/google-circle.svg" alt="Google Sign In"></button>
        </section>

        <section>
            <h5>Don't have an account yet?</h5>

            <a [routerLink]="['/signup']">Sign Up</a>
        </section>
    </form>
</section>

<nav [class]="navClass">
    <section class="nav-logo-wrapper">
        <a [routerLink]="['/']" class="nav-logo">
            <img [src]="isLightTheme ? '../../assets/images/misc/logo_gray.svg' : '../../assets/images/misc/logo.svg'" alt="Build-A-Bot Logo">
        </a>
    </section>

    <ul id="nav-links">
        <li routerLinkActive="active">
            <div class="dropdown">
                <button class="button-primary" [routerLink]="['/about']" (click)="toggleDropdown($event)">About</button>
                <div class="dropdown-content">
                    <a [routerLink]="['/team']">Meet the Team</a>
                    <a href="mailto:buildabot@du.edu">Contact Us</a>
                </div>
            </div>
        </li>

        <li *ngIf="isAdmin" routerLinkActive="active">
            <div class="dropdown">
                <button class="button-primary" [routerLink]="['/admin/articles']">Admin</button>
                <div class="dropdown-content">
                    <a [routerLink]="['/admin/bots']">Bots</a>
                    <a [routerLink]="['/admin/articles']">Articles</a>
                    <a [routerLink]="['/admin/scenarios']">Scenarios</a>
                    <a [routerLink]="['/admin/users']">Users</a>
                </div>
            </div>
        </li>

        <li *ngIf="!isLoggedIn">
            <button class="button-primary" (click)="signup()">Sign Up</button>
        </li>

        <li *ngIf="!isLoggedIn">
            <button [ngClass]="{'button-secondary': true, 'button-secondary-invert': headerSize === 'none'}" (click)="login()">Log In</button>
        </li>

        <li *ngIf="isLoggedIn && !pageIsBuilder">
            <button class="button-primary" [routerLink]="['/build']">Build</button>
        </li>

        <li *ngIf="isLoggedIn && pageIsBuilder">
            <button class="button-primary" [routerLink]="['/dashboard']">Dashboard</button>
        </li>

        <li>
            <img [ngClass]="{'theme-toggle': headerSize === 'none', 'theme-toggle-invert': headerSize !== 'none'}" *ngIf="!isLightTheme" src="../../assets/icons/sun-dark.svg" (click)="toggleLightTheme()" alt="Toggle light mode">
            <img [ngClass]="{'theme-toggle': headerSize !== 'none', 'theme-toggle-invert': headerSize === 'none'}" *ngIf="isLightTheme" src="../../assets/icons/moon-dark.svg" (click)="toggleLightTheme()" alt="Toggle dark theme">
        </li>
    </ul>

    <img *ngIf="isLoggedIn" src="../../assets/images/pfp/{{currentUser.pfp}}.png" id="user-dropdown" class="dropdown-toggle" (click)="toggleMenuDropdown()">
    <span id="menu-dropdown" [class]="showMenuDropdown ? 'dropdown-toggle open' : 'dropdown-toggle'" (click)="toggleMenuDropdown()">
        <span></span>
        <span></span>
        <span></span>
    </span>
    <ul *ngIf="showMenuDropdown" class="dropdown-items">

        <li *ngIf="isLoggedIn && !pageIsBuilder" class="mobile-link">
            <button class="button-primary" [routerLink]="['/build']">Build</button>
        </li>

        <li *ngIf="isLoggedIn && pageIsBuilder" class="mobile-link">
            <button class="button-primary" [routerLink]="['/dashboard']">Dashboard</button>
        </li>

        <li *ngIf="isAdmin" routerLinkActive="active">
            <button class="button-primary" [routerLink]="['/admin/articles']">Admin</button>
        </li>

        <li *ngIf="!isLoggedIn" class="mobile-link">
            <button class="button-primary" (click)="signup()">Sign Up</button>
        </li>

        <li *ngIf="!isLoggedIn" class="mobile-link">
            <button class="button-secondary" (click)="login()">Log In</button>
        </li>

        <li *ngIf="isLoggedIn"><a [routerLink]="['/dashboard']">Dashboard</a></li>
        <li *ngIf="isLoggedIn"><a [routerLink]="['/profile']">Profile</a></li>
        <li *ngIf="isLoggedIn"><a (click)="logout()" class="logout-link">Sign Out</a></li>
        <li class="mobile-link">
            <img class="theme-toggle" *ngIf="!isLightTheme" src="../../assets/icons/sun-dark.svg" (click)="toggleLightTheme()" alt="Toggle light mode">
            <img class="theme-toggle" *ngIf="isLightTheme" src="../../assets/icons/moon-dark.svg" (click)="toggleLightTheme()" alt="Toggle dark theme">
        </li>
    </ul>
</nav>

import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { CurrentTeam, Alumni } from "./people";

@Component({
    selector: "person",
    templateUrl: "./person.html",
    styleUrls: ["./person.scss"],
})
export class PersonComponent implements OnInit {
    _route;

    id: string;

    person;

    static parameters = [ActivatedRoute, Router];
    constructor(public route: ActivatedRoute, public router: Router) {
        this._route = route;
        this.router = router;
        route.params.subscribe((params) => {
            this.id = params.person;
            this.person = CurrentTeam.find(x => x.tag == this.id) ?? Alumni.find(x => x.tag == this.id);
            if (!this.person) {
                this.router.navigateByUrl("/team");
            }
        });
    }

    ngOnInit() {}
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CollapseModule } from 'ngx-bootstrap/collapse';


import { NavbarComponent } from './navbar/navbar.component';
import { FooterComponent } from './footer/footer.component';

import { MatButtonModule } from '@angular/material/button';

import { MatDialogModule } from '@angular/material/dialog';

import { RouterModule } from '@angular/router';

import { ScrollSpyDirective } from './scroll-spy.directive';
import { HeaderComponent } from './header/header.component';

@NgModule({
    imports: [
        CommonModule,
        CollapseModule,
        MatButtonModule,
        MatDialogModule,
        RouterModule,
    ],
    declarations: [
        NavbarComponent,
        FooterComponent,
        ScrollSpyDirective,
        HeaderComponent
    ],
    exports: [
        NavbarComponent,
        FooterComponent,
        ScrollSpyDirective,
        HeaderComponent
    ],
})
export class DirectivesModule {}

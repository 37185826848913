import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ThemeService {
    localStoragePrefersLight = window.localStorage.getItem("theme-preference") == "light-theme";
    mediaPrefersLight = window.matchMedia("(prefers-color-scheme: light)").matches;

    private isLightThemeSubject = new BehaviorSubject<boolean>(this.localStoragePrefersLight || this.mediaPrefersLight || false);
    isLightTheme$ = this.isLightThemeSubject.asObservable();

    toggleTheme() {
        const currentTheme = this.isLightThemeSubject.getValue();
        this.isLightThemeSubject.next(!currentTheme);

        window.localStorage.removeItem("theme-preference");
        window.localStorage.setItem("theme-preference", currentTheme ? "dark-theme" : "light-theme");
    }
}

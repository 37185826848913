
<footer>
  <section class="footer-wrapper">
    <section class="legal-wrapper">
      <h5><a href="/legal/tos">Terms of Service</a></h5>
      <h5><a href="/legal/privacy">Privacy Policy</a></h5>
      <h5><a href="/legal/cookies">Cookie Policy</a></h5>
    </section>
    
    <div class="social-icons">
      <a target="_blank" href="https://www.instagram.com/dubuildabot/"><i class="fa fa-instagram"></i></a>
      <a target="_blank" href="https://www.facebook.com/DU-Want-to-Build-a-Bot-110671587936905/"><i
          class="fa fa-facebook"></i></a>
      <a target="_blank" href="https://twitter.com/build_du"><i class="fa fa-twitter"></i></a>
      <a target="_blank" href="https://www.linkedin.com/company/du-want-to-build-a-bot"><i class="fa fa-linkedin"></i></a>
    </div>
  
    <h5>Copyright 2023, D-U-Want-To-Build-A-Bot Project, University of Denver</h5>
  </section>

</footer>
// @flow
import { Component, Inject } from '@angular/core';
import {
    MatDialog,
    MatDialogRef,
    MAT_DIALOG_DATA,
} from "@angular/material/dialog";

@Component({
    selector: 'login-dialog',
    templateUrl: './login-dialog.html',
    styleUrls: ['../account.scss']
})
export class LoginDialogComponent {
    static parameters = [MAT_DIALOG_DATA, MatDialogRef];
    constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef?: MatDialogRef<LoginDialogComponent>) {
    }

    onLoggedIn(login: boolean) {
        if (login) {
            this.dialogRef.close();
        }
    }
}



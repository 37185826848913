<main> 
    <img class="logo" id="logo-light" src="../assets/images/misc/logo_gray.svg" alt="Build-A-Bot robot logo">
    <img class="logo" id="logo-dark" src="../assets/images/misc/logo.svg" alt="Build-A-Bot robot logo">
    
    <section class="content" id="main-content">
        <h1>Do you want to build a bot?</h1>
        <h2>Building your own robot has been made easy. Our novel game makes robot design simple and accessible.</h2>
        
     <div class="button-learn-container">
        <div class="button-container">
        <button class="button-primary" routerLink="/signup">Sign Up</button>
        <button class="button-secondary" routerLink="/login">Login</button>
        </div>
        <div class="learn-more-container">
        <p class="learn-more" routerLink="/about">Learn more</p>
    </div>
    </div>
    </section>
    
    <section class="content" id="visual-content">
        <img src="../assets/images/landing/landing-robot-screenshot.jpg"
        alt="A completed robot that has been build using this platform.">

        <div id="gradient-overlay"></div>
    
        <!-- Pause/Play -->
        <button class="button-primary playpause" id="pause" hidden>
            <img src="../assets/images/icons/pause.svg" alt="Pause Icon"> Pause</button>
        
         <button class="button-primary playpause" id="play">
            <img src="../assets/images/icons/play.svg" alt="Play Icon" > Play</button>
    </section>
</main>
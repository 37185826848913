import { Injectable } from "@angular/core";
import {
    Router,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
} from "@angular/router";
import { UserService } from "./user.service";
import { AuthService } from "./auth.service";
import { pipe, map, catchError, of } from "rxjs";
import { User } from "../shared/types/user";

@Injectable({
    providedIn: 'root'
})
export class AuthGuard {
    router;
    userService;
    authService;

    static parameters = [UserService, AuthService, Router];
    constructor(
        userService: UserService,
        authService: AuthService,
        router: Router
    ) {
        this.userService = userService;
        this.authService = authService;
        this.router = router;
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.userService.get().pipe(
            map((user: User) => {
                if (!user) {
                    console.log("User not found");
                    this.router.navigate([
                        "/login"
                    ]);
                    return false;
                }

                if (route.data.admin) {
                    const isAdmin = this.authService.isAdmin();
                    if (isAdmin) return true;
                    this.router.navigate([
                        "/home"
                    ]);
                    return false;
                } else {
                    if (user.status === "pending_profile") {
                        if (route.url[0].path === "profile") {
                            return true;
                        }
                        this.router.navigate([
                            "/profile",
                            { status: "pending_profile" },
                        ]);
                        return false;
                    } else if (user.status === "active") {
                        return true;
                    }
                }
                this.router.navigate([
                    "/login"
                ]);
                return false;
            }),
            catchError((err) => {
                this.router.navigate([
                    "/login"
                ]);
                return of(false);
            })
        )
    }
}
